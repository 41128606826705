import { default as indexwVdryfG1R4Meta } from "/home/ubuntu/beekk_nuxtjs/pages/index.vue?macro=true";
import { default as logine9i3bTWaWlMeta } from "/home/ubuntu/beekk_nuxtjs/pages/login.vue?macro=true";
import { default as dashboardT16BHu9r6CMeta } from "/home/ubuntu/beekk_nuxtjs/pages/main/dashboard.vue?macro=true";
import { default as listsB15RjisCBDMeta } from "/home/ubuntu/beekk_nuxtjs/pages/member/lists.vue?macro=true";
import { default as _91id_939OiNvjfE2KMeta } from "/home/ubuntu/beekk_nuxtjs/pages/product/details/[id].vue?macro=true";
import { default as registerKXbiRhFdBcMeta } from "/home/ubuntu/beekk_nuxtjs/pages/product/register.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexwVdryfG1R4Meta || {},
    component: () => import("/home/ubuntu/beekk_nuxtjs/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logine9i3bTWaWlMeta || {},
    component: () => import("/home/ubuntu/beekk_nuxtjs/pages/login.vue")
  },
  {
    name: "main-dashboard",
    path: "/main/dashboard",
    component: () => import("/home/ubuntu/beekk_nuxtjs/pages/main/dashboard.vue")
  },
  {
    name: "member-lists",
    path: "/member/lists",
    component: () => import("/home/ubuntu/beekk_nuxtjs/pages/member/lists.vue")
  },
  {
    name: "product-details-id",
    path: "/product/details/:id()",
    component: () => import("/home/ubuntu/beekk_nuxtjs/pages/product/details/[id].vue")
  },
  {
    name: "product-register",
    path: "/product/register",
    component: () => import("/home/ubuntu/beekk_nuxtjs/pages/product/register.vue")
  }
]